import { Link } from "react-scroll";
import '../../style/HeroSection.css';

export default function HeroSection() {
    return (
      <section id="HeroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <h1 className="hero--section--welcome">Welcome to Rachel Farmer Wellness</h1>
            <h2 className="hero--section--title">
              <span className="hero--section-title--color">Yoga and Holistic Health</span>{" "}
              <br />
            </h2>
            <p className="hero--section-description">
            Rachel Farmer Wellness is a holistic health company inspired by yoga philosophy to harmonize the body, mind, and spirit. 
            I offer various types of yoga classes and personalized health coaching both in person and online to help you achieve your 
            most balanced, happiest, and healthiest life. 
            </p>
          </div>
          <button className="btn btn-primary">
          <Link
        spy={true}
        smooth={true}
        offset={60}
        duration={500}
        to="Contact"
      >
        Get in Touch
      </Link></button>
        </div>
        <div className="hero--section--img">
          <img src="./img/hero-pic.jpg" alt="Hero Section" />
        </div>
      </section>
    );
  }