import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../../style/AboutMe.css';

export default function AboutMe() {
    return (
        <section id="AboutMe" className="about--section">
            <div className="about--section--img">
                <Carousel
                    showThumbs={false}
                    infiniteLoop={true} 
                    showStatus={false}
                    swipeable
                    emulateTouch
                    centerMode
                    centerSlidePercentage={33}
                    autoPlay={false} 
                    selectedItem={4}
                >
                     <div>
                        <img src="./img/u-grad.png" alt="1 Description" />
                    </div>
                    <div>
                        <img src="./img/pa-grad.png" alt="2 Description" />
                    </div>
                    <div>
                        <img src="./img/pa.jpg" alt="3 Description" />
                    </div>
                    <div>
                        <img src="./img/bali-grad.png" alt="4 Description" />
                    </div>
                    <div>
                        <img src="./img/u-grad.png" alt="1 Description" />
                    </div>
                    <div>
                        <img src="./img/pa-grad.png" alt="2 Description" />
                    </div>
                    <div>
                        <img src="./img/pa.jpg" alt="3 Description" />
                    </div>
                    <div>
                        <img src="./img/bali-grad.png" alt="4 Description" />
                    </div>
                    <div>
                        <img src="./img/u-grad.png" alt="1 Description" />
                    </div>
                    <div>
                        <img src="./img/pa-grad.png" alt="2 Description" />
                    </div>
                    <div>
                        <img src="./img/pa.jpg" alt="3 Description" />
                    </div>
                    <div>
                        <img src="./img/bali-grad.png" alt="4 Description" />
                    </div>
                    
                </Carousel>
            </div>
            <div className="about--section--box">
                <div className="about--section--content">
                    <h1 className="about--section--heading">About Me</h1>
                    <p className="about--section--description">
                        As both a 200 hour certified yoga instructor and a board-certified Physician Assistant, PA-C, with a passion for holistic wellness, I bring a deep understanding of the human body and its needs. My journey began back in 2013 when I tried my first yoga class as a fun activity to try with my mom. It wasn’t until I was working my first job as a PA-C when I started to feel very stressed that I dove back into yoga as a way to relax and find mental peace in 2022. Throughout the year, I began to fall in love with yoga and decided to further my practice by attending House of Om’s 200 hour yoga teacher training in Bali in the winter of 2023. Completing the 3 week program was one of the best decisions I have ever made for not only my own practice but also for my career. While I loved the relationships that I fostered while working in medicine, becoming a yoga teacher opened me up to the possibility of helping and healing outside the clinic in other ways. It inspired me to further my education by enrolling in Insitute of Integrative Nutriton’s Health Coaching program where I am currently taking courses to become a board certified health coach, bridging the gap between what I learned through the Western medical model as a physician assistant and Ayurveda, the oldest medical system originating in India as a yoga teacher.
                    </p>
                    {/* <p className="about--section--description">
                        My approach is integrative and holistic, focusing on the whole person—body, mind, and soul. Whether you're looking to enhance your physical health, improve your relationships, find mental clarity, or cultivate a deeper sense of inner peace, I’m here to support you on your journey. Let’s work together to create a balanced, healthy lifestyle that empowers you to thrive in every way.
                    </p> */}
                </div>
            </div>
        </section>
    );
}
