import React, { useState, useEffect } from 'react';
import '../../style/Modal.css';

const PrivacyDisclaimerModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('privacy');

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; 
    } else {
      document.body.style.overflow = 'auto'; 
    }

    return () => {
      document.body.style.overflow = 'auto'; 
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>X</button>
        <div className="modal-tabs">
          <button
            className={`modal-tab ${activeTab === 'privacy' ? 'active' : ''}`}
            onClick={() => setActiveTab('privacy')}
          >
            Privacy Policy
          </button>
          <button
            className={`modal-tab ${activeTab === 'disclaimer' ? 'active' : ''}`}
            onClick={() => setActiveTab('disclaimer')}
          >
            Disclaimer
          </button>
        </div>
        <div className="modal-body">
          {activeTab === 'privacy' && (
            <div>
              <h2>Privacy Policy for Rachel Farmer Wellness</h2>
              <p><strong>Last updated: 9/1/24</strong></p>

              <h3>Introduction</h3>
              <p>
                Welcome to the website of Rachel Farmer Wellness ("we," "our," "us"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://rachelfarmerwellness.com">rachelfarmerwellness.com</a>. Please read this Privacy Policy carefully. By accessing or using our website, you agree to the terms outlined here.
              </p>

              <h3>Information We Collect</h3>
              <p>We may collect personal and non-personal information about you in a variety of ways:</p>
              <ul>
                <li><strong>Personal Information:</strong> If you voluntarily provide it to us, we may collect your name, email address, phone number, or other contact information when you fill out forms, subscribe to newsletters, or contact us.</li>
                <li><strong>Non-Personal Information:</strong> We automatically collect certain information about your device and usage, including your IP address, browser type, device information, referring URLs, and pages you visit on our site.</li>
              </ul>

              <h3>How We Use Your Information</h3>
              <p>We use the information we collect in the following ways:</p>
              <ul>
                <li>To provide, operate, and maintain our website.</li>
                <li>To improve, personalize, and expand our website.</li>
                <li>To understand and analyze how you use our website.</li>
                <li>To communicate with you, including for customer service, updates, and marketing.</li>
                <li>To comply with legal requirements and protect our legal rights.</li>
              </ul>

              <h3>Cloudflare Analytics</h3>
              <p>
                Our website uses Cloudflare's analytics service, which does not use cookies or collect personal data. Cloudflare provides us with insights into website performance and usage without tracking individual users. By using this website, you consent to the collection and use of data as described by Cloudflare's privacy practices.
              </p>

              <h3>Third-Party Links</h3>
              <p>
                Our website may contain links to third-party websites, such as registration pages for classes or products and services not operated or controlled by us. We are not responsible for the content, privacy policies, or practices of these third-party sites. We encourage you to review the privacy policies of any third-party sites you visit.
              </p>

              <h3>Security of Your Information</h3>
              <p>
                We use administrative, technical, and physical security measures to protect your personal information. While we take reasonable steps to protect your data, no method of transmission over the internet or method of electronic storage is completely secure, and we cannot guarantee absolute security.
              </p>

              <h3>Your Rights and Choices</h3>
              <p>
                Depending on your location, you may have certain rights regarding your personal information, such as the right to access, update, or delete your data. If you wish to exercise any of these rights, please contact us at <a href="mailto:rachelfarmerwellness@gmail.com">rachelfarmerwellness@gmail.com</a>.
              </p>

              <h3>Changes to This Privacy Policy</h3>
              <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are encouraged to review this Privacy Policy periodically for any changes.
              </p>

              <h3>Contact Us</h3>
              <p>
                If you have any questions about this Privacy Policy, please contact us at: <br />
                Email: <a href="mailto:rachelfarmerwellness@gmail.com">rachelfarmerwellness@gmail.com</a>
              </p>
            </div>
          )}
          {activeTab === 'disclaimer' && (
            <div>
              <h2>Disclaimer for Rachel Farmer Wellness</h2>
              <h3>General Disclaimer</h3>
              <p>
                The information provided on this website <a href="https://rachelfarmerwellness.com">rachelfarmerwellness.com</a> is for general informational purposes only. All information on the site is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.
              </p>

              <h3>External Links Disclaimer</h3>
              <p>
                Our website may contain links to third-party websites or services that are not owned or controlled by Rachel Farmer Wellness. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services. You should read the terms and conditions and privacy policy of any third-party website you visit.
              </p>

              <h3>Health and Wellness Disclaimer</h3>
              <p>
                The content on this website is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on this website.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrivacyDisclaimerModal;
