import '../../style/ContactMe.css';

export default function ContactMe() {
  return (
    <section id="Contact" className="contact--section">
      <div>

        <h2 className="contact--header" >Contact Me</h2>
        <p className="contact--subheader">
          Have any questions? Looking to get started? Fill out this form to get in touch!
        </p>
      </div>
      <form className="contact--form--container" action="https://formspree.io/f/xnnaogpb" method="POST">
        <div className="container">
          <label htmlFor="first-name" className="contact--label">
            <span className="text-md">First Name</span>
            <input
              type="text"
              className="contact--input text-md"
              name="first-name"
              id="first-name"
              required
            />
          </label>
          <label htmlFor="last-name" className="contact--label">
            <span className="text-md">Last Name</span>
            <input
              type="text"
              className="contact--input text-md"
              name="last-name"
              id="last-name"
              required
            />
          </label>
          <label htmlFor="email" className="contact--label">
            <span className="text-md">Email</span>
            <input
              type="email"
              className="contact--input text-md"
              name="_replyto"  
              id="email"
              required
            />
          </label>
          <label htmlFor="phone-number" className="contact--label">
            <span className="text-md">Phone Number</span>
            <input
              type="number"
              className="contact--input text-md"
              name="phone-number"
              id="phone-number"
              required
            />
          </label>
        </div>
        <label htmlFor="choose-topic" className="contact--label">
          <span className="text-md">Choose a topic</span>
          <select id="choose-topic" className="contact--input text-md" name="topic">
            <option>Select One...</option>
            <option>Private Session</option>
            <option>Group Class</option>
            <option>Corporate Class</option>
            <option>Health Coaching</option>
            <option>Other</option>
          </select>
        </label>
        <label htmlFor="message" className="contact--label">
          <span className="text-md">Message</span>
          <textarea
            className="contact--input text-md"
            id="message"
            name="message"  
            rows="8"
            placeholder="Type your message..."
          />
        </label>
        <div className="contact-btn-container">
          <button className="btn btn-primary contact--form--btn" type="submit">Submit</button>
        </div>
      </form>
    </section>
  );
}
