import React from 'react';
import Calendar from '../../components/Calendar/Calendar';

const CalendarPage = () => {
  return (
    <section id="Calendar" className="calendar--section">
      <div>
      <h1 id="CalendarHeader"className="calendar--section--heading">Event Calendar</h1>
      <Calendar />
    </div>
    </section>
  );
};

export default CalendarPage;
