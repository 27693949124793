import data from "../../data/index.json";
import '../../style/MyDiscounts.css';


export default function MyDiscounts() {
  return (
    <section id="MyDiscounts" className="discounts--section">
      <div className="discounts--container-box">
        <div className="discounts--container">
          <p className="sub--title"></p>
          <h2 className="section--heading">Brand Discounts</h2>
        </div>
        <div>
        </div>
      </div>
      <div className="discounts--section--container">
        {data?.discounts?.map((item, index) => (
          <div key={index} className="discounts--section--card" >
            <div className="discounts--section--img">
              <img src={item.src} alt="Placeholder" />
            </div>
            <div className="discounts--section--card--content">
              <div>
                <h3 className="discounts--section--title">{item.title}</h3>
                <p className="text-md">{item.description}</p>
              </div>
              <a href={item.link} className="text-sm discounts--link" target="_blank" rel="noopener noreferrer">
                Link to store
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 20 19"
                  fill="none"
                >
                  <path
                    d="M4.66667 1.66675H18V15.0001M18 1.66675L2 17.6667L18 1.66675Z"
                    stroke="currentColor"
                    stroke-width="2.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}