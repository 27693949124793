import React, { useState } from 'react';

const EventList = ({ events, selectedDate }) => {
  const [expandedEventId, setExpandedEventId] = useState(null);

  const toggleEventDetails = (eventId) => {
    if (expandedEventId === eventId) {
      setExpandedEventId(null);
    } else {
      setExpandedEventId(eventId);
    }
  };

  const handleRedirect = (event, link) => {
    event.stopPropagation(); 
    if (link) {
      window.open(link, '_blank'); 
    }
  };

  const extractLinkAndDescription = (description) => {
    // regex to extract URL
    const urlRegex = /(https?:\/\/[^\s"']+)/;
    const match = description.match(urlRegex);
    let eventLink = match ? match[0] : '';

    // stripping HTML tags from the description
    const cleanDescription = description.replace(/<\/?[^>]+(>|$)/g, '');
    const remainingDescription = cleanDescription.replace(urlRegex, '').trim();

    return { eventLink, remainingDescription };
  };

  const dayEvents = events.filter(
    event => new Date(event.start).toDateString() === selectedDate.toDateString()
  );

  return (
    <div className="event-list">
      <h3>Events on {selectedDate.toDateString()}</h3>
      {dayEvents.length > 0 ? (
        <ul>
          {dayEvents.map(event => {
            const { eventLink, remainingDescription } = extractLinkAndDescription(event.description);

            return (
              <li 
                key={event.id} 
                onClick={() => toggleEventDetails(event.id)} 
                className={`event-item ${expandedEventId === event.id ? 'expanded' : ''}`}
              >
                <div className="event-summary">
                  <strong>{event.title}</strong>
                  <p>{new Date(event.start).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})} - {new Date(event.end).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})}</p>
                </div>

                {expandedEventId === event.id && (
                  <div className="event-details">
                    {remainingDescription && <p>{remainingDescription}</p>}
                    {event.location && <p><br /><strong>Location:</strong> {event.location}</p>}
                    {eventLink && (
                      <button 
                        className="event-list--btn" 
                        onClick={(e) => handleRedirect(e, eventLink)}
                      >
                        Go to Event Link
                      </button>
                    )}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <p>No events for this day.</p>
      )}
    </div>
  );
};

export default EventList;
