import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import EventList from './EventList'; 
import 'react-calendar/dist/Calendar.css';
import '../../style/App.css';
import '../../style/Calendar.css';

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(new Date());
  const [error, setError] = useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const fetchEventsForYear = async (year) => {
    try {
      const response = await fetch(`/api/calendar/events?year=${year}`); 
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const formattedEvents = data.map(event => ({
        id: event.id,
        title: event.summary,
        start: new Date(event.start.dateTime || event.start.date),
        end: new Date(event.end.dateTime || event.end.date),
        description: event.description || '',
        location: event.location || '',
      }));
      setEvents(formattedEvents);
    } catch (err) {
      console.error("Error fetching events: ", err);
      setError("Failed to fetch events");
    }
  };

  useEffect(() => {
    fetchEventsForYear(currentYear);
  }, [currentYear]);

  return (
    <div className="calendar-container">
      {error ? (
        <p>{error}</p>
      ) : (
        <div className="calendar-event-wrapper">
          <Calendar
            value={date}
            onChange={setDate}
            onActiveStartDateChange={({ activeStartDate }) => {
              const newYear = activeStartDate.getFullYear();
              if (newYear !== currentYear) {
                setCurrentYear(newYear);
              }
            }}
            tileContent={({ date, view }) => {
              if (view === 'month' || view === 'year') {
                const dayEvents = events.filter(
                  event => new Date(event.start).toDateString() === date.toDateString()
                );
                return (
                  <ul>
                    {dayEvents.map(event => (
                      <li key={event.id}>{event.title}</li>
                    ))}
                  </ul>
                );
              }
              return null;
            }}
          />
          <EventList events={events} selectedDate={date} />
        </div>
      )}
    </div>
  );
};

export default MyCalendar;
